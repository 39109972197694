import { Box, SxProps } from '@mui/material';
import { ReactNode } from 'react';

/**
 * Component for page body, allows scrolling and easily centering content
 */
export const Content = ({
  children,
  centered = false,
  sx = {},
}: {
  children: ReactNode,
  centered?: boolean,
  sx?: SxProps
}) => (
  <Box sx={{
    overflowY: 'auto',
    flex: 1,
    paddingX: 2,
    ...(centered ? {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    } : {}),
    ...sx,
  }}
  >
    {children}
  </Box>
);
