import {
  BrowserRouter as ReactRouter,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { FC, ReactElement, useEffect } from 'react';
import { Unauthenticated } from './components/unauthenticated';
import { NotFound } from './pages/notFound';
import { LandingPage } from './pages/landingPage';
import { config } from './config';
import { ClothingPage } from './pages/clothingPage';
import { CheckoutPage } from './pages/checkoutPage';
import { ProcessOrdersPage } from './pages/processOrdersPage';
import { OrdersPage } from './pages/ordersPage';
import { ProcessOrderDetails } from './pages/processOrdersPage/partials/processOrderDetails';
import { EquipmentPage } from './pages/equipmentPage';
import { OrderPage } from './pages/ordersPage/partials/orderPage';
import './router.scss';
import { useHasRoles } from './msal';
import { AccessDeniedPage } from './pages/accessDenied';
import { Role } from './core/types/util/role';

const Redirect: FC<{ to: string }> = ({ to }) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  }, []);
  return null;
};

interface AuthRouteProps {
  roles: Role | Role[];
  children: ReactElement;
}

const AuthRoute = ({ roles, children }: AuthRouteProps) => {
  const { hasRole, isAuthenticated } = useHasRoles(roles);

  if (!isAuthenticated) {
    return null;
  }
  if (hasRole) {
    return children || null;
  }
  return <Redirect to="/ingentilgang" />;
};

export const Router = () => {
  const nonExternalRoles: Role[] = ['user', 'admin', 'approver'];
  const approverRoles: Role[] = ['approver', 'admin'];

  return (
    <MsalAuthenticationTemplate
      authenticationRequest={{ scopes: [config.SCOPE] }}
      interactionType={InteractionType.Redirect}
      errorComponent={Unauthenticated}
    >
      <div className="router-wrapper">
        <ReactRouter>
          <Routes>
            <Route path="/ingentilgang" element={<AccessDeniedPage />} />
            <Route path="/bestillinger" element={<OrdersPage />} />
            <Route path="/bestillinger/:id" element={<OrderPage />} />
            <Route
              path="/bestillinger/behandle"
              element={<AuthRoute roles={approverRoles}><ProcessOrdersPage /></AuthRoute>}
            />
            <Route
              path="/bestillinger/behandle/:id"
              element={<AuthRoute roles={approverRoles}><ProcessOrderDetails /></AuthRoute>}
            />
            <Route path="/utstyr/kasse" element={<CheckoutPage type="Equipment" />} />
            <Route
              path="/arbeidsklaer/kasse"
              element={<AuthRoute roles={nonExternalRoles}><CheckoutPage type="Clothing" /></AuthRoute>}
            />
            <Route path="/utstyr" element={<EquipmentPage />} />
            <Route
              path="/arbeidsklaer"
              element={<AuthRoute roles={nonExternalRoles}><ClothingPage /></AuthRoute>}
            />
            <Route
              path="/arbeidsklaer"
              element={<AuthRoute roles={nonExternalRoles}><ClothingPage /></AuthRoute>}
            />
            <Route path="/" element={<LandingPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </ReactRouter>
      </div>
    </MsalAuthenticationTemplate>
  );
};
