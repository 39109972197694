import {
  Box,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';

export const DataField = ({
  label,
  children = '',
  sx = {},
}: {
  label: string,
  children: string|ReactNode,
  sx?: SxProps<Theme>,
}) => (
  <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    ...sx,
  }}
  >
    <Typography sx={{
      color: 'rgba(0, 0, 0, 0.33)',
      textShadow: '0px 0px 1px rgba(0, 0, 0, 0.289)',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '13px',
    }}
    >
      {label}
    </Typography>
    {children}
  </Box>
);
