import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Header } from '../../../components/header';
import { Page } from '../../../components/page';
import { useGetOrderQuery } from '../../../core/redux/webshop';
import { OrderDetails } from '../../../components/orderDetails';
import { Content } from '../../../components/content';
import '../style.scss';

export const OrderPage = () => {
  const params = useParams<'id'>();
  const { data: order } = useGetOrderQuery(params.id || skipToken);

  return (
    <Page className="checkout-page">
      <Header showBack> Bestilling </Header>
      <Content centered sx={{ p: 2, alignItems: 'unset' }}>
        {!order && (
          <CircularProgress />
        )}
        {order && (
          <OrderDetails order={order} />
        )}
      </Content>
    </Page>
  );
};
