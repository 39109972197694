import {
  Box,
  Typography,
} from '@mui/material';
import { ProductCard } from './product';
import { ClothingAvailability } from '../../core/types/clothingAvailability';
import { Product } from '../../core/types/product';
import { SelectItem } from '../../core/types/util/selectItem';

/** Collapsable section with products in a given category */
export const CategorySection = ({
  category,
  userClothing,
  equipment,
  getAllowed = () => 0,
} : {
  category: SelectItem,
  userClothing?: ClothingAvailability
  equipment?: Product[]
  getAllowed?: (p: Product) => number,
}) => (
  <Box
    key={category.id}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      margin: 10,
      gap: 2,
      mb: 10,
      '@media (max-width:785px)': {
        alignItems: 'center',
        margin: 4,
      },
    }}
  >
    <Box
      display="flex"
      alignItems="center"
      gap={2}
    >
      <Typography fontSize={30} component="div">
        {category.label}
      </Typography>
    </Box>
    <Box
      display="grid"
      flex={1}
      gap={3}
      gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))"
      sx={{
        '@media (max-width:785px)': {
          gridTemplateColumns: '1fr',
          justifyItems: 'center',
        },
      }}
    >
      {userClothing && userClothing.products
        .filter((p) => p.productGroup === category.label)
        .sort((a, b) => a.name.localeCompare(b.name, 'nb'))
        .map((p) => (
          <ProductCard key={p.name} product={p} remainingAllowance={getAllowed(p)} type="Clothing" />
        ))}
      {equipment && equipment
        .filter((p) => p.itemType === category.label)
        .sort((a, b) => a.name.localeCompare(b.name, 'nb'))
        .map((p) => (
          <ProductCard key={p.name} product={p} type="Equipment" />
        ))}
    </Box>
  </Box>
);
