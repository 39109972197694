import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  IconButton,
  Box,
  Skeleton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMemo, useState } from 'react';
import {
  CartItem,
} from '../../../core/redux/cart';
import { Product } from '../../../core/types/product';
import { InputField } from '../../../components/inputField';
import { ItemControl } from '../../../components/itemControl';
import ImageMissing from '../../../assets/image_missing.png';
import { config } from '../../../config';

export const ProductOrderCard = ({
  item,
  getAllowed = () => 0,
  subQuantity = () => null,
  addQuantity = () => null,
  deleteCartItem = () => null,
  setComment = () => null,
  showComment = true,
  disableEdit = false,
  subtitle,
} : {
  item: CartItem,
  getAllowed: (item: Product) => number,
  subQuantity?: () => void,
  addQuantity?: () => void,
  deleteCartItem?: () => void,
  setComment?: (id: string, comment: string) => void,
  showComment: boolean,
  disableEdit?: boolean,
  subtitle?: string | null,
}) => {
  const [useFallbackImage, setUseFallbackImage] = useState<boolean>(false);
  const image = useMemo(() => {
    const url = item.image ? `${config.API_URL}/${item.image}` : item.product.image;
    return (
      url && !useFallbackImage
        ? url
        : ImageMissing
    );
  }, [item, useFallbackImage]);

  const commentRequired = item.type === 'Clothing' && item.product && getAllowed(item.product) + 1 < 1;
  const commentError = commentRequired && item.comment?.trim().length === 0;
  return (
    <Card
      key={item.name}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        margin: 2,
        pt: 1,
      }}
    >
      <Box
        display="flex"
        sx={{
          '@media (max-width:700px)': {
            flexDirection: 'column',
          },
        }}
      >
        <CardContent sx={{
          display: 'flex',
          flex: 1,
          alignItems: 'flex-start',
          gap: 2,
          '@media (max-width:550px)': {
            flexWrap: 'wrap',
          },
        }}
        >
          <CardMedia
            component="img"
            alt="noe"
            image={image}
            sx={{
              objectFit: 'contain',
              width: 100,
              '@media (max-width:700px)': {
                width: 'unset',
                height: 80,
              },
            }}
            onError={() => setUseFallbackImage(true)}
          />
          <Box sx={{
            minWidth: 200,
            fontSize: '18px',
            '@media (max-width:475px)': {
              fontSize: '16px',
              minWidth: 150,
            },
            '@media (max-width:400px)': {
              minWidth: 100,
            },
          }}
          >
            <Typography color="black">
              {item.name}
            </Typography>
            <Typography>
              {item.size ? `Str: ${item.size}` : ''}
            </Typography>
            {typeof subtitle === 'string' && (
              <Typography sx={{ fontSize: 14, fontStyle: 'italic' }}>
                {subtitle}
              </Typography>
            )}
            {subtitle === null && <Skeleton variant="text" sx={{ fontSize: 14 }} />}
          </Box>
        </CardContent>
        <CardActions sx={{ flex: 1 }}>
          <Box sx={{
            alignSelf: 'stretch',
            flex: 1,
            paddingTop: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
          >
            {showComment && (
              <InputField
                label="Kommentar"
                size="small"
                multiline
                minRows={2}
                maxRows={3}
                disabled={disableEdit}
                inputProps={{
                  title: commentRequired
                    ? 'Påkrevd siden dette produktet er ekstra'
                    : undefined,
                }}
                required={commentRequired}
                error={commentError}
                helperText={commentError
                  ? 'Påkrevd siden dette produktet er ekstra'
                  : ' '}
                value={item.comment}
                onChange={(comment) => setComment(item.id, comment)}
              />
            )}
            <Box sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
            >
              <ItemControl
                subDisabled={item.amount <= 1 || disableEdit}
                addDisabled={disableEdit}
                amount={item.amount * item.product.amountPerUnit}
                subQuantity={subQuantity}
                addQuantity={addQuantity}
              />
              <IconButton onClick={deleteCartItem} disabled={disableEdit}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        </CardActions>
      </Box>
      <Typography
        fontSize={9}
        fontWeight="bold"
        color="gray"
        alignSelf="flex-end"
        pr={1}
      >{item.id}
      </Typography>
    </Card>
  );
};
