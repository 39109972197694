export const translateErrorCode = (endPoint: string, errorText: string, errorCode = 500) => {
  if (errorText.toLocaleLowerCase().includes('not found')) {
    return 'Ikke funnet';
  }
  if (errorText.includes('authentication')) {
    return 'Et autoriseringsproblem har oppstått';
  }
  if (errorCode === 403) {
    return 'En autoriseringsfeil har oppstått';
  }
  return 'En feil har oppstått';
};
