import CircleIcon from '@mui/icons-material/Circle';
import { Box } from '@mui/material';
import { OrderStatus } from '../../core/types/enums/orderStatus';

const statusMap = {
  Created: { s: 'Opprettet', c: '#ff8400' },
  RequiresApproval: { s: 'Krever godkjenning', c: '#f6d500' },
  Sent: { s: 'Sendt', c: '#0025aa' },
  Cancelled: { s: 'Kansellert', c: '#555555' },
  Declined: { s: 'Avvist', c: '#ff0000' },
};

export const OrderStatusIcon = ({
  currStatus,
  urgent = undefined,
}: {
  currStatus: OrderStatus,
  urgent?: boolean,
}) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
    <CircleIcon sx={{ color: statusMap[currStatus].c }} />
    {statusMap[currStatus].s}
    {urgent && (
      <>
        &nbsp;(hastebestilling)
      </>
    )}
  </Box>
);
