import {
  Badge, IconButton,
} from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useSelector } from '../../core/hooks/redux';
import { OrderType } from '../../core/types/enums/orderType';

export const CartIcon = ({
  onClick = () => null,
  type,
} : {
  onClick: () => void,
  type: OrderType
}) => {
  const items = useSelector((s) => s.cart.items?.reduce((acc, val) => (
    val.type === type
      ? acc + val.amount
      : acc
  ), 0));
  return (
    <Badge
      color="info"
      badgeContent={items}
      invisible={!items}
      sx={{
        '& .MuiBadge-badge': {
          right: 11,
          top: 11,
        },
      }}
    >
      <IconButton color="secondary" onClick={onClick}>
        <ShoppingCartIcon sx={{ fontSize: 35 }} />
      </IconButton>
    </Badge>
  );
};
