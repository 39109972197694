import { Box, Typography } from '@mui/material';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import { Order } from '../../../core/types/order';
import { OrderItemType } from '../../../components/orderItem';

export const useConvertProcess = (order: Order): OrderItemType => {
  const obj: OrderItemType = {
    id: order.uid,
    path: `/bestillinger/behandle/${order.uid}`,
    status: order.status,
    title: `${order?.employee?.fullName ?? order.createdByName} - ${order.employeeId}`,
    type: 'Clothing',
    urgent: order.urgent,
    items: (
      <Box>
        {order.rows.slice(0, 3).map((r) => <Typography key={r.uid}>{`${r.product} x${r.amount}`}</Typography>)}
        {order.rows.length > 3 && <Typography fontWeight="bold">...</Typography>}
      </Box>
    ),
    totalAmount: order.rows.reduce((acc, curr) => acc + curr.amount, 0),
    icons: (
      <CheckroomIcon color="primary" />
    ),
  };

  return obj;
};
