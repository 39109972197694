import { useLocation } from 'react-router-dom';

export const urlCombine = (a: string, b: string) => `${a.replace(/\/$/, '')}/${b.replace(/^\//, '')}`;

export const stopPropagation = (e: { stopPropagation: () => void }) => {
  e.stopPropagation();
};

export const useQuery = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  return Object.fromEntries(query);
};

export const pluralize = (v: number, unit: string, plural: string = 'er') => {
  if (v === 1) return `${v} ${unit}`;
  return `${v} ${unit}${plural}`;
};
