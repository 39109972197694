import { Box, Typography } from '@mui/material';
import { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorCatcher extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidMount(): void {
    this.setState({ hasError: false });
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <Box display="flex" alignItems="center" height="99vh" justifyContent="center">
          <Box display="flex" alignItems="center" flexDirection="column">
            <Typography textAlign="center" fontSize="4vmax">Det har skjedd en feil</Typography>
            <Typography textAlign="center" fontSize="1.5vmax">En visningsfeil har oppstått<br /> Du kan <a href={window.location.href}>laste om</a> siden for å prøve på nytt</Typography>
          </Box>
        </Box>
      );
    }

    return children;
  }
}
