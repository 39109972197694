import { Box, Button } from '@mui/material';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import OrdersIcon from '@mui/icons-material/ViewStream';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ConstructionIcon from '@mui/icons-material/Construction';
import { Page } from '../../components/page';
import { Header } from '../../components/header';
import { Content } from '../../components/content';
import { useHasRoles, useIsApprover } from '../../msal';

const NavButton = ({
  path,
  icon,
  label,
}: {
  path: string,
  icon: ReactNode,
  label: string
}) => {
  const navigate = useNavigate();
  return (
    <Button size="large" variant="contained" onClick={() => navigate(path)}>
      {icon}
      <Box flex={1}>{label}</Box>
    </Button>
  );
};

export const LandingPage = () => {
  const { isApprover } = useIsApprover();
  const { hasRole: isExternal } = useHasRoles('external');

  return (
    <Page className="webshop-page">
      <Header>
        Webshop
      </Header>
      <Content centered>
        <Box
          maxWidth={300}
          width="100%"
          display="flex"
          flexDirection="column"
          gap={3}
        >
          {!isExternal && (
            <NavButton
              path="/arbeidsklaer"
              icon={<CheckroomIcon />}
              label="Arbeidsklær"
            />
          )}
          <NavButton
            path="/utstyr"
            icon={<ConstructionIcon />}
            label="Utstyr"
          />
          <NavButton
            path="/bestillinger"
            icon={<OrdersIcon />}
            label="Mine bestillinger"
          />
          {isApprover && (
          <NavButton
            path="/bestillinger/behandle"
            icon={<ChecklistIcon />}
            label="Godkjenning"
          />
          )}
        </Box>
      </Content>
    </Page>

  );
};
