import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import { InputField } from '../inputField';
import { Modal } from '../modal';

export const CommentModal = ({
  open = false,
  value = '',
  onChange = () => null,
  onClose = () => null,
  onSubmit = () => null,
  type = 'approve',
}: {
  open?: boolean,
  value: string,
  onChange?: (comment: string) => void,
  onClose?: () => void,
  onSubmit?: (type: 'reject'|'approve') => void,
  type?: 'reject' | 'approve'
}) => (
  <Modal title={type === 'approve' ? 'Godkjenn' : 'Avslå'} compactHeader open={open} onClose={onClose}>
    <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
      <Box display="flex">
        <Typography>
          {type === 'approve'
            ? 'Siden du har endret bestillingen må du skrive en kommentar'
            : 'Skriv inn kommentar for å avslå'}
        </Typography>
      </Box>
      <InputField
        sx={{ mt: 1 }}
        multiline
        minRows={2}
        label="Kommentar"
        value={value}
        onChange={onChange}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
        <Button variant="outlined" onClick={onClose}>Avbryt</Button>
        <Button
          color={type === 'approve' ? 'primary' : 'error'}
          variant="contained"
          onClick={() => onSubmit(type)}
          disabled={value.trim().length === 0}
        >
          {type === 'approve' ? 'Godkjenn' : 'Avslå'}
        </Button>
      </Box>
    </Box>
  </Modal>
);
