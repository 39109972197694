import { OrderItem } from '../../../components/orderItem';
import { Order } from '../../../core/types/order';
import { useConvertClothing } from './useConvertClothing';

export const ClothingOrderItem = ({
  order,
}: {
  order: Order,
}) => {
  const data = useConvertClothing(order);
  return (
    <OrderItem order={data} />
  );
};
