import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Modal } from '../modal';
import { InputField } from '../inputField';

/** Displays a confirmation modal to handle extra orders */
export const ConfirmModal = ({
  open = false,
  onClose = () => null,
  onSubmit = () => null,
  reason = 'product',
  productName = 'dette produktet',
  categoryName = 'denne produktkategorien',
}: {
    open?: boolean,
    onClose?: () => void,
    onSubmit?: (comment: string) => void,
    reason?: 'product' | 'category',
    productName?: string
    categoryName?: string,
}) => {
  const [comment, setComment] = useState<string>('');

  useEffect(() => {
    if (!open) setComment('');
  }, [open]);

  return (
    <Modal title="Ekstrabestilling" compactHeader open={open} onClose={onClose}>
      <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
        <Box display="flex">
          <Typography marginRight={0.5}>
            Du har nådd grensen for
          </Typography>
          <Typography fontWeight="bold">{reason === 'product'
            ? `produktet: ${productName}`
            : `kategorien: ${categoryName}`}
          </Typography>
        </Box>
        <Typography>Du kan fortsatt bestille, men det må godkjennes av nærmeste leder. Fyll inn
          begrunnelse for å fortsette med bestillingen.
        </Typography>
        <InputField
          sx={{ mt: 1 }}
          multiline
          minRows={2}
          label="Kommentar"
          value={comment}
          onChange={setComment}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button variant="outlined" onClick={onClose}>Avbryt</Button>
          <Button
            variant="contained"
            onClick={() => onSubmit(comment)}
            disabled={comment.trim().length === 0}
          >
            Legg til
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
