import React, {
  ReactNode,
  useMemo,
  useState,
} from 'react';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { AccountInfo } from '@azure/msal-browser';
import { Logout } from '@mui/icons-material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { logOut, msalInstance } from '../../../msal';
import { useGetVersionQuery } from '../../../core/redux/version';
import { getEnvironmentName } from '../../../config';
import { stopPropagation } from '../../../core/helpers/functions';
import {
  useDeleteAliasMutation,
  useGetAliasQuery,
  useGetAllWorkersQuery,
  usePutAliasMutation,
} from '../../../core/redux/webshop';
import { Staff } from '../../../core/types/staff';
import { SelectItem } from '../../../core/types/util/selectItem';
import { SearchSelect } from '../../searchSelect';
import { useRoles } from '../../../core/hooks/useRoles';

const stableArray: unknown[] = [];
/** SelectItem for when no alias is active */
const none = { id: '', label: 'Ingen' };

/**
 * Displays profile icon and has a menu that provides log out and alias
 * functionality. The alias functionality is only available for certain user
 * roles.
 */
export const Profile = ({
  extraMenuItems = null,
}: {
  extraMenuItems?: ReactNode,
}) => {
  const account: AccountInfo|null = msalInstance.getActiveAccount();
  const { roles: userRoles, isAuthenticated } = useRoles();
  const canUseAlias = useMemo(() => (
    userRoles.some((r) => r === 'alias')
  ), [userRoles]);

  const {
    data: alias,
  } = useGetAliasQuery(account?.username || skipToken, { skip: !canUseAlias });
  const {
    data: users = stableArray as Staff[],
  } = useGetAllWorkersQuery(undefined, { skip: !canUseAlias });

  const [removeAlias] = useDeleteAliasMutation();
  const [updateAlias] = usePutAliasMutation();

  const userSelectItems = useMemo((): SelectItem[] => {
    const userItems = users
      .filter((u) => u.email !== undefined && u.email !== '')
      .map((u) => ({
        id: u.email as string,
        label: `${u.fullName} (${u.department})`,
      }));
    userItems.unshift(none);
    return userItems;
  }, [users]);

  const activeAlias = useMemo((): SelectItem => (
    alias
      ? { id: alias.aliasId, label: alias.alias.fullName }
      : none
  ), [alias]);

  const changeAlias = (v: SelectItem | undefined) => {
    if (!account) return;
    if ((!v || v.id === '') && activeAlias.id !== '') {
      removeAlias(account.username).unwrap().then(() => {
        window.location.reload();
      });
      return;
    }
    if (!v || v.id === '') return;
    updateAlias({ id: account.username, target: `${v.id}` })
      .unwrap()
      .then(() => {
        window.location.reload();
      });
  };

  const initials = useMemo(() => {
    let nameArray: string[] | null = null;
    if (alias?.alias) {
      nameArray = alias.alias.fullName.split(' ');
    } else if (account?.name) {
      nameArray = account.name.split(' ');
    }
    const firstName = nameArray ? nameArray[0] : null;
    const lastName = nameArray ? nameArray[nameArray.length - 1] : null;
    if (!firstName || !lastName) { // can not get username
      return 'N/A';
    }
    return (firstName.charAt(0) + lastName.charAt(0));
  }, [alias, account]);

  const { data: serverVersion } = useGetVersionQuery();

  const env = getEnvironmentName(document.baseURI);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box display="flex" gap={1}>
      {isAuthenticated && (
        <div>
          <Tooltip
            title={<Typography fontSize={14}>Profil</Typography>}
            placement="bottom"
            enterDelay={300}
            enterNextDelay={300}
            arrow
          >
            <IconButton
              edge="end"
              color="inherit"
              aria-label="profile"
              onClick={handleClick}
            >
              <Avatar sx={{
                width: 36,
                height: 36,
                backgroundColor: alias?.alias
                  ? '#dd6f1a'
                  : undefined,
              }}
              >{initials}
              </Avatar>
            </IconButton>
          </Tooltip>
        </div>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          <Avatar />
          {' '}
          {account?.name}
        </MenuItem>
        <Divider />
        <MenuItem onClick={logOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logg ut
        </MenuItem>
        <MenuItem
          sx={{ userSelect: 'text' }}
          onClick={stopPropagation}
          disableRipple
          disableTouchRipple
        >
          <Typography variant="caption">
            {process.env.REACT_APP_VERSION} {
              serverVersion && (<>/ {serverVersion.version}</>)
            } ({env !== 'prod' ? env : ''})
          </Typography>
        </MenuItem>
        {canUseAlias && (
          <MenuItem
            onClick={stopPropagation}
            onKeyDown={stopPropagation}
            sx={{ paddingTop: 1 }}
            disableRipple
            disableTouchRipple
          >
            <SearchSelect
              label="Brukeralias"
              value={activeAlias}
              onChange={changeAlias}
              size="small"
            >
              {userSelectItems}
            </SearchSelect>
          </MenuItem>
        )}
        {canUseAlias && (
          <MenuItem
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 1,
            }}
            onClick={stopPropagation}
            disableRipple
            disableTouchRipple
          >
            <Typography sx={{ fontSize: 14 }}>Roller</Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
              {userRoles.map((r) => (
                <Chip
                  key={r}
                  color={r === 'alias' ? 'primary' : undefined}
                  label={r}
                />
              ))}
            </Box>
          </MenuItem>
        )}
        {extraMenuItems}
      </Menu>
    </Box>
  );
};
