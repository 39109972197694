import { FC, ReactElement } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const Modal: FC<{
  title: string|ReactElement,
  children: ReactElement,
  statusIcon?: ReactElement,
  onClose?: () => void,
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false,
  compactHeader?: boolean,
  open?: boolean,
  footer?: ReactElement,
}> = ({
  title,
  children,
  statusIcon,
  onClose = () => null,
  maxWidth = 'md',
  compactHeader = false,
  open = true,
  footer,
}) => (
  <Dialog
    open={open}
    onClose={() => {
      onClose();
    }}
    maxWidth={maxWidth}
    fullWidth
  >
    <Box display="flex">
      <Box flex={1}>
        <DialogTitle>{title}</DialogTitle>
      </Box>
      <Box display="flex" gap={1}>
        {statusIcon}
        <IconButton sx={{ marginRight: '15px' }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>

    <DialogContent sx={compactHeader ? { pt: 0.2 } : undefined}>
      {children}
    </DialogContent>

    {footer && (
    <DialogActions>
      {footer}
    </DialogActions>
    )}

  </Dialog>
);
