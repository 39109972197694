import {
  Checkbox as MuiCheckbox,
  Box,
  CheckboxProps,
  Typography,
  TypographyProps,
  BoxProps,
} from '@mui/material';

export const Checkbox = ({
  label = '',
  checked = false,
  onChange = () => null,
  checkboxProps = {},
  labelProps = {},
  wrapperProps = {},
  disabled = false,
}: {
    /** Checkbox label */
    label?: string,
    /** Whether the checkbox is currently checked */
    checked?: boolean,
    /** Exectuted every time the checkbox is clicked */
    onChange?: (v: boolean) => void,
    /** Passed directly to checkbox component */
    checkboxProps?: CheckboxProps,
    /** Passed directly to the typography component */
    labelProps?: TypographyProps,
    /** Passed directly to the box component */
    wrapperProps?: BoxProps,
    /** Disable checkbox */
    disabled?: boolean,
}) => (
  <Box
    display="flex"
    gap={1}
    alignItems="center"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...wrapperProps}
  >
    <MuiCheckbox
      checked={checked}
      onChange={() => onChange(!checked)}
      disabled={disabled}
      color="primary"
      sx={{ padding: 0 }}
        // eslint-disable-next-line react/jsx-props-no-spreading
      {...checkboxProps}
    />
    <Typography
      onClick={() => onChange(!checked)}
      sx={{ cursor: 'pointer' }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...labelProps}
    >{label}
    </Typography>
  </Box>
);
