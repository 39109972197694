import { OrderItem } from '../../../components/orderItem';
import { Order } from '../../../core/types/order';
import { useConvertEquipment } from './useConvertEquipment';

export const EquipmentOrderItem = ({
  order,
}: {
  order: Order,
}) => {
  const data = useConvertEquipment(order);
  return (
    <OrderItem order={data} />
  );
};
