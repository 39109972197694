/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { Product } from '../types/product';
import { OrderType } from '../types/enums/orderType';

const stateName = 'cart';

export type CartItem = {
  id: string,
  type: OrderType,
  name: string,
  size?: string,
  productGroupCode?: string,
  amount: number,
  cost?: number,
  product: Product,
  image?: string,
  comment?: string,
}

interface InitialState {
  items: CartItem[]
}

const initialState: InitialState = {
  items: [],
};

const cartSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    addCartItem(state, action: PayloadAction<CartItem>) {
      const item = state.items.find((s) => s.id === action.payload.id);
      if (item) {
        item.amount += action.payload.amount;
        if (action.payload.comment) {
          item.comment = action.payload.comment;
        }
      } else {
        state.items = [...state.items, action.payload];
      }
    },
    deleteCartItem(state, action: PayloadAction<string>) {
      state.items = state.items.filter((s) => s.id !== action.payload);
    },
    setCartItems(state, action: PayloadAction<CartItem[]>) {
      state.items = action.payload;
    },
    emptyCart(state, action: PayloadAction<OrderType>) {
      state.items = state.items.filter((i) => i.type !== action.payload);
    },
    addQuantity(state, action: PayloadAction<string>) {
      const item = state.items.find((s) => s.id === action.payload);
      if (!item) return;
      item.amount += 1;
    },
    subQuantity(state, action: PayloadAction<string>) {
      const item = state.items.find((s) => s.id === action.payload);
      if (!item) return;
      if ((item.amount - 1) < 1) return;
      item.amount -= 1;
    },
    setComment(state, action: PayloadAction<{ id: string, comment: string }>) {
      const { id, comment } = action.payload;
      const item = state.items.find((s) => s.id === id);
      if (!item) return;
      item.comment = comment;
    },
    reset(state) {
      Object.assign(state, initialState);
    },
  },
});

export const {
  addCartItem,
  deleteCartItem,
  setCartItems,
  emptyCart,
  addQuantity,
  subQuantity,
  setComment,
  reset,
} = cartSlice.actions;

export default persistReducer({ key: stateName, storage }, cartSlice.reducer);
