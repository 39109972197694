import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useGetOrderQuery } from '../../../core/redux/webshop';
import { OrderDetails } from '../../../components/orderDetails';
import { Header } from '../../../components/header';
import { Page } from '../../../components/page';
import { Content } from '../../../components/content';
import '../style.scss';

export const ProcessOrderDetails = () => {
  const params = useParams() as any;
  const { data: order } = useGetOrderQuery(params.id);

  return (
    <Page>
      <Header showBack>Behandle bestilling</Header>
      <Content centered sx={{ p: 2, alignItems: 'unset' }}>
        {!order && (
          <CircularProgress />
        )}
        {order && (
          <OrderDetails order={order} editable />
        )}
      </Content>
    </Page>
  );
};
