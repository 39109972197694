import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
  Configuration,
} from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useMemo } from 'react';
import { config } from './config';
import { Role } from './core/types/util/role';

// MSAL configuration
const configuration: Configuration = {
  auth: {
    clientId: config.CLIENT_ID,
    authority: config.AUTHORITY,
    redirectUri: config.FRONTEND_URL,
    postLogoutRedirectUri: config.FRONTEND_URL,
  },
};

const msalInstance = new PublicClientApplication(configuration);

if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  const payload = event.payload as AuthenticationResult;
  if (event.eventType === EventType.LOGIN_SUCCESS && payload.account) {
    const { account } = payload;
    msalInstance.setActiveAccount(account);
  }
});

export const account = msalInstance.getActiveAccount();

export const logOut = () => {
  msalInstance.logoutRedirect();
};

export const username = account ? account.username : null;

export { msalInstance };
export const msalAccount = msalInstance.getActiveAccount();

/**
 * Checks if user is a part of any of the specified roles
 *
 * @param rs The roles to check for
 * @returns True if the user has any of the roles, false otherwise.
 */
export const useHasRoles = <T extends string>(rs: T[] | T) => {
  const roles = typeof rs === 'string' ? [rs] : rs;
  const { instance, inProgress } = useMsal();
  const a = instance.getActiveAccount();
  const hasRole = useMemo(() => {
    try {
      const { roles: authRoles = [] } = a?.idTokenClaims as { roles: string[] | undefined };
      if (roles.some((r) => authRoles.includes(r))) return true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('Could not get roles');
    }
    return false;
  }, [roles.join(',')]);

  return { hasRole, isAuthenticated: !!a, inProgress };
};

/**
 * Check if user has access to approve.
 * @returns flag and indicators
 */
export const useIsApprover = () => {
  const { hasRole, isAuthenticated, inProgress } = useHasRoles<Role>(['admin', 'approver']);
  return { isApprover: hasRole, isAuthenticated, inProgress };
};
