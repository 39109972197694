import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReactNode } from 'react';
import { OrderType } from '../../core/types/enums/orderType';
import { OrderStatusIcon } from '../OrderStatusIcon';
import { OrderStatus } from '../../core/types/enums/orderStatus';

export type OrderItemType = {
  id: number|string,
  title: string,
  status: OrderStatus,
  items: ReactNode,
  totalAmount: number,
  icons: ReactNode,
  type: OrderType,
  /** Path to navigate to when clicked */
  path: string,
  urgent?: boolean,
}

export const OrderItem = ({
  order,
}: {
  order: OrderItemType,
}) => {
  const navigate = useNavigate();

  return (
    <Card>
      <CardActionArea onClick={() => {
        navigate(order.path);
      }}
      >
        <CardContent>
          <Box className="line-wrapper">
            <Box className="header left">
              {order.title}
            </Box>
            <Box className="right transform-status">
              <OrderStatusIcon currStatus={order.status} urgent={order.urgent} />
            </Box>
          </Box>

          <Box className="flex-load-contact" sx={{ overflow: 'hidden', gap: 1 }}>
            {order.items}
          </Box>

          <Box display="flex" flex={1} justifyContent="space-between">
            <Typography fontWeight="bold">{`Total antall: ${order.totalAmount}`}</Typography>
            {order.icons}
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
