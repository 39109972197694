import { Box, Button } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useSameUrl, useStateUrl } from '../../core/hooks/params';
import { useGetEquipmentCategoriesQuery } from '../../core/redux/webshop';

const Breadcrumb = ({
  label,
  onClick,
  main = false,
}: {
  label?: string,
  onClick: () => void,
  main?: boolean,
}) => {
  if (!label) return null;
  return (
    <Box sx={{
      display: 'inline-flex',
      alignItems: 'center',
      overflow: 'hidden',
      ':last-child': {
        flex: '1 0',
      },
    }}
    >
      {!main && (<ChevronRightIcon sx={{ fontSize: 16 }} color="primary" />)}
      <Button
        size="small"
        variant="text"
        color="primary"
        sx={{
          fontSize: 14,
          whiteSpace: 'nowrap',
          display: 'inline',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        onClick={onClick}
      >
        {label}
      </Button>
    </Box>
  );
};

export const PagePath = () => {
  const [mainSelection] = useStateUrl<string>('kategori', undefined);
  const [selection] = useStateUrl<string|undefined>('gruppe', undefined);
  const [search] = useStateUrl<string|undefined>('search', undefined);
  const [,, remove] = useSameUrl();
  const { data: mainCategories } = useGetEquipmentCategoriesQuery();

  const changePage = (level: number) => () => {
    const toRemove = ['search'];
    if (level < 2 && selection) toRemove.push('gruppe');
    if (level < 1 && mainSelection) toRemove.push('kategori');
    if (toRemove.length > 0) remove(toRemove, false);
  };

  const mainLabel = mainCategories?.find((x) => x.code === mainSelection)?.description ?? mainSelection;

  return (
    <Box sx={{ display: 'flex', maxWidth: '100vw' }}>
      <Breadcrumb label="Start" onClick={changePage(0)} main />
      { search
        ? (
          <Breadcrumb label={`Søk: ${search}`} onClick={() => {}} />
        )
        : (
          <>
            <Breadcrumb label={mainLabel} onClick={changePage(1)} />
            <Breadcrumb label={selection} onClick={changePage(2)} />
          </>
        )}
    </Box>
  );
};
