import { useMemo } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Page } from '../../components/page';
import { Header } from '../../components/header';
import { useStateUrl } from '../../core/hooks/params';
import { Content } from '../../components/content';
import { CategoryButtonGroup } from '../../components/productPageComponents/categoryButtonGroup';
import { CategorySection } from '../../components/productPageComponents/category';
import { useGetEquipmentCategoriesQuery, useGetProductsInCategoryQuery, useSearchProductsQuery } from '../../core/redux/webshop';
import { PagePath } from '../../components/pagePath';

export const EquipmentPage = () => {
  const [mainSelection, setMainSelection] = useStateUrl<string>('kategori', undefined, true, false);
  const [selection, setSelection] = useStateUrl<string|undefined>('gruppe', undefined, true, false);
  const [search] = useStateUrl('search');

  const { data: mainCategories, isFetching: mainCategoriesLoading } = useGetEquipmentCategoriesQuery();
  const { data: productsInCategory, isFetching: productsInCategoryLoading } = useGetProductsInCategoryQuery(
    mainSelection || '',
    { refetchOnMountOrArgChange: true, skip: !mainSelection },
  );

  const { data: searchResult, isFetching: searchLoading } = useSearchProductsQuery(
    search || skipToken,
    { skip: search === undefined },
  );

  const isFetching = useMemo(() => (
    productsInCategoryLoading || searchLoading
  ), [productsInCategoryLoading, searchLoading]);

  const products = useMemo(
    () => {
      if (search === undefined && productsInCategory !== undefined) return productsInCategory;
      if (search !== undefined && searchResult !== undefined) return searchResult;
      return [];
    },
    [search, productsInCategory, searchResult],
  );

  const mainCategoryItems = useMemo(
    () => mainCategories
      ?.map((i) => ({ id: i.code, label: i.description }))
      .sort((a, b) => a.label.localeCompare(b.label, 'nb')) || [],
    [mainCategories],
  );

  const subCategories = useMemo(() => {
    const temp = new Set<string>(products?.map((x) => x.itemType));
    return Array.from(temp, (i) => ({ id: i, label: i }))
      .sort((a, b) => a.label.localeCompare(b.label, 'nb'));
  }, [products]);

  return (
    <Page>
      <Header search>
        Utstyr
      </Header>
      <PagePath />
      {!mainSelection && !search ? (
        <Content
          sx={{
            flexGrow: 1,
            flexShrink: 0,
            alignItems: 'start',
            justifyContent: 'start',
            justifyItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          { mainCategoriesLoading && (
            <CircularProgress
              size={100}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
                zIndex: 1000,
              }}
            />
          ) }
          <CategoryButtonGroup
            buttonSx={{
              width: '30vw',
              height: '20vw',
              fontWeight: 'bold',
              fontSize: 'larger',
              maxWidth: '400px',
              maxHeight: '200px',
              '@media (max-width: 800px)': {
                width: '100%',
                minWidth: '100%',
              },
            }}
            categories={mainCategoryItems || []}
            selection={mainSelection}
            setSelection={(id) => setMainSelection(id)}
          />
        </Content>
      ) : (
        <>
          {!search && !isFetching && (
          <Box>
            <CategoryButtonGroup
              categories={mainCategoryItems || []}
              selection={mainSelection}
              setSelection={(id) => setMainSelection(id)}
            />
            <CategoryButtonGroup
              categories={subCategories}
              selection={selection}
              setSelection={(id) => setSelection(id)}
            />
          </Box>
          )}
          <Content sx={{ padding: 0 }}>
            { isFetching && (
            <CircularProgress
              size={100}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-50px',
                marginLeft: '-50px',
                zIndex: 1000,
              }}
            />
            )}
            {!isFetching && subCategories && subCategories.length === 0 && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  margin: 1,
                  '@media (max-width:785px)': {
                  },
                }}
              >
                <Typography variant="h6">
                  Ingen resultater for &apos;{search}&apos;
                </Typography>
              </Box>
            )}
            {!isFetching && subCategories && subCategories
              .filter((c) => (
                // If we are searching we don't want to filter too
                search === undefined && selection
                  ? c.id === selection
                  : true
              ))
              .map((c) => (
                <CategorySection key={c.id} category={c} equipment={products} />
              ))}
          </Content>
        </>
      )}
    </Page>
  );
};
