import { useMemo } from 'react';
import { CircularProgress } from '@mui/material';
import { useGetClothingQuery } from '../../core/redux/webshop';
import { Page } from '../../components/page';
import { Header } from '../../components/header';
import { CategorySection } from '../../components/productPageComponents/category';
import { Content } from '../../components/content';
import { useGetAllowed } from '../../core/helpers/limits';
import { useStateUrl } from '../../core/hooks/params';
import { SelectItem } from '../../core/types/util/selectItem';
import { CategoryButtonGroup } from '../../components/productPageComponents/categoryButtonGroup';
import { PagePath } from '../../components/pagePath';

export const ClothingPage = () => {
  const { data: userClothing, isLoading } = useGetClothingQuery();
  const getAllowed = useGetAllowed();
  const [selection, setSelection] = useStateUrl<string>('gruppe', undefined, true);

  const categories = useMemo((): SelectItem[] => {
    const items = new Set<string>();
    userClothing?.products
      ?.forEach((p) => {
        if (p.productGroup) {
          items.add(p.productGroup);
        }
      });
    const cats = Array.from(items, (i) => ({ id: i, label: i }))
      .sort((a, b) => a.label.localeCompare(b.label, 'nb'));
    return cats;
  }, [userClothing]);

  return (
    <Page>
      <Header>
        Arbeidsklær
      </Header>
      <PagePath />
      <CategoryButtonGroup
        categories={categories}
        selection={selection}
        setSelection={(id) => setSelection(id)}
      />
      <Content sx={{ padding: 0 }}>
        { isLoading && (
        <CircularProgress
          size={100}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
            zIndex: 1000,
          }}
        />
        ) }
        {categories && categories
          .filter((c) => (selection ? c.id === selection : true))
          .map((c) => (
            <CategorySection
              key={c.id}
              category={c}
              userClothing={userClothing}
              getAllowed={getAllowed}
            />
          ))}
      </Content>
    </Page>
  );
};
