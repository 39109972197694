import { urlCombine } from './core/helpers/functions';

 type EnvironmentName = 'local' | 'dev' | 'test' | 'prod' | 'preProd' | '';

 type ConfigurationSettings = {
    FRONTEND_URL: string;
    BACKEND_URL: string;
    CLIENT_ID: string;
  }

export interface Configuration {
    BACKEND_URL: string; // Backend URL
    CLIENT_ID: string; // Frontend Application/Client ID
    API_URL: string; // Backend API URL (URL + API_PATH)
    HUB_URL: string; // Backend signalR hub URL (URL + HUB_PATH)
    SCOPE: string; // Backend scope

    API_PATH: string; // Added on the end of API_URL
    HUB_PATH: string; // Added on the end of API_URL

    AUTHORITY: string; // Frontend Directory/Tenant ID
    FRONTEND_URL: string; // Frontend URL
    TIME_FORMAT: string; // Backend time format
  }

// Having everything here provides autocomplete
const common: Configuration = {
  BACKEND_URL: '', // Backend URL
  CLIENT_ID: '', // Frontend Application/Client ID
  API_URL: '', // Actual API URL
  HUB_URL: '', // Actual hub URL
  SCOPE: '', // Backend Appication/Client ID - automaticly set from client_id
  API_PATH: '/api/v1.0', // API path on backend
  HUB_PATH: '/hubs/v1.0', // hub path on backend
  AUTHORITY: 'https://login.microsoftonline.com/68aad708-5e24-4f71-9201-974eac2ecb55', // Frontend Directory/Tenant ID
  FRONTEND_URL: '', // Frontend URL
  TIME_FORMAT: 'YYYY-MM-DDTHH:mm:ss.SSSS', // Backend time format
};

const createConfig = (c: ConfigurationSettings): Configuration => ({
  ...common,
  API_URL: urlCombine(`${c.BACKEND_URL}`, `${common.API_PATH}`),
  HUB_URL: urlCombine(`${c.BACKEND_URL}`, `${common.HUB_PATH}`),
  SCOPE: `api://${c.CLIENT_ID}/access`,
  ...c,
});

export const local: ConfigurationSettings = {
  FRONTEND_URL: 'http://localhost:3005',
  BACKEND_URL: 'https://localhost:44310/',
  CLIENT_ID: '1c909690-d487-4230-a4d8-eff25e233cef',
};
export const dev: ConfigurationSettings = {
  FRONTEND_URL: 'https://red-flower-0bac9f803.2.azurestaticapps.net',
  BACKEND_URL: 'https://webshop-backend-utv.azurewebsites.net/',
  CLIENT_ID: '1c909690-d487-4230-a4d8-eff25e233cef',
};
export const test: ConfigurationSettings = {
  FRONTEND_URL: 'https://webshop-test.tsmaskin.no',
  BACKEND_URL: 'https://webshop-test-backend.azurewebsites.net/',
  CLIENT_ID: '2f65dc3f-b3f5-4903-b1a8-1a3f657feec6',
};
export const prod: ConfigurationSettings = {
  FRONTEND_URL: 'https://webshop.tsmaskin.no',
  BACKEND_URL: 'https://webshop-prod-backend.azurewebsites.net/',
  CLIENT_ID: 'eab17c7b-4858-4ce9-9407-31d8e9fd4bb6',
};

export const preProd: ConfigurationSettings = {
  FRONTEND_URL: 'https://gentle-cliff-03a71c703.2.azurestaticapps.net',
  BACKEND_URL: 'https://webshop-prod-backend.azurewebsites.net/',
  CLIENT_ID: 'eab17c7b-4858-4ce9-9407-31d8e9fd4bb6',
};

export const getEnvironmentName = (env?: string): EnvironmentName => {
  const url = new URL(env || document.baseURI);
  if (url.hostname === 'localhost') return 'local';
  if (url.hostname === 'red-flower-0bac9f803.2.azurestaticapps.net' && url.protocol === 'https:') return 'dev';
  if (url.hostname === 'webshop-test.tsmaskin.no' && url.protocol === 'https:') return 'test';
  if (url.hostname === 'webshop.tsmaskin.no' && url.protocol === 'https:') return 'prod';
  if (url.hostname === 'gentle-cliff-03a71c703.2.azurestaticapps.net' && url.protocol === 'https:') return 'preProd';
  return '';
};

export const getEnvironment = (
  envName: EnvironmentName,
): ConfigurationSettings => {
  if (envName === 'dev') return dev;
  if (envName === 'test') return test;
  if (envName === 'prod') return prod;
  if (envName === 'preProd') return preProd;
  return local;
};

export const config = createConfig(getEnvironment(getEnvironmentName()));
