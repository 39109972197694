import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/** Determine which of the two shop sections is active */
export const usePageType = () => {
  const location = useLocation();
  const type = useMemo(() => {
    if (location.pathname.includes('arbeidsklaer')) return 'Clothing';
    if (location.pathname.includes('utstyr')) return 'Equipment';
    return undefined;
  }, [location]);
  return type;
};

/** Get the base URL for the current shop section */
export const usePagePath = () => {
  const location = useLocation();
  const path = useMemo(() => {
    if (location.pathname.includes('arbeidsklaer')) return '/arbeidsklaer';
    if (location.pathname.includes('utstyr')) return '/utstyr';
    return undefined;
  }, [location]);
  return path;
};
