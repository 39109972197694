import { Box, Button, SxProps } from '@mui/material';
import { SelectItem } from '../../core/types/util/selectItem';

export const CategoryButtonGroup = ({
  categories,
  selection,
  fullWidth = undefined,
  buttonSx = {},
  setSelection = () => null,
}: {
  categories: SelectItem[],
  selection: string|undefined,
  fullWidth?: boolean,
  buttonSx?: SxProps,
  setSelection: (id: string) => void,
}) => {
  if (selection) return null;
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        margin: 1,
        flexWrap: 'wrap',
        '&>.MuiButton-root': { borderRadius: 0 },
        '&>.MuiButton-root:first-child': { borderTopLeftRadius: '4px' },
        '&>.MuiButton-root:last-child': { borderBottomRightRadius: '4px' },
      }}
    >
      {categories && categories.map((c) => (
        <Button
          key={c.id}
          variant="contained"
          fullWidth={fullWidth}
          color={selection === c.id ? 'secondary' : 'primary'}
          onClick={() => setSelection(`${c.id}`)}
          sx={{
            whiteSpace: 'nowrap', ...buttonSx,
          }}
        >
          {c.label}
        </Button>
      ))}
    </Box>
  );
};
