import {
  isRejectedWithValue,
  Middleware,
} from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { translateErrorCode } from '../helpers/translate';

/** ignore errors on listed endpoints */
const ignoreEndpoints = [
  'getMyOrganization',
  'getAlias',
];

export const loggerMiddleware: Middleware = () => (next) => (action) => {
  if (ignoreEndpoints.includes(action?.meta?.arg?.endpointName)) return next(action);

  if (isRejectedWithValue(action)) {
    try {
      let errorMessage = 'Error: Unable to complete request';
      let responseCode = 500;
      if (action?.payload?.data?.title || action?.payload?.data?.errors?.length === 1) {
        errorMessage = action?.payload?.data?.title || action?.payload?.data?.errors[0];
      } else if (action?.payload?.data) {
        const messageLines = action.payload.data?.split('\r', 1);
        if (messageLines) {
          [errorMessage] = messageLines;
        }
      }
      if (action?.payload?.data?.status) {
        responseCode = action.payload.data.status;
      } else if (action?.payload?.originalStatus) {
        responseCode = action.payload.originalStatus || 500;
      }
      toast.error(translateErrorCode(action?.meta?.arg?.endpointName, errorMessage, responseCode));
      // eslint-disable-next-line no-console
      console.error({
        errorCode: responseCode,
        message: errorMessage,
        path: action?.meta?.baseQueryMeta?.request?.url,
      });
    } catch (e) {
      toast.error('En feil har oppstått');
      // eslint-disable-next-line no-console
      console.error('Data parsing error', action.payload);
    }
  }
  return next(action);
};
