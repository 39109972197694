import { useCallback, useMemo } from 'react';
import { useGetClothingQuery } from '../redux/webshop';
import { Product } from '../types/product';
import { useSelector } from '../hooks/redux';
import { CartItem } from '../redux/cart';

/**
 * Calculates the values required for computing limits on products and returns a
 * function that checks a single item quickly.
 *
 * @param userId ID of user to check limits for. Defaults to current user
 * @param cartItems List of items currently being ordered. Defaults to redux cart
 * @returns A function to check whether an item is within the limits
 */
export const useGetAllowed = (userId?: string|number|undefined, cartItems?: CartItem[]) => {
  const { data } = useGetClothingQuery(userId);
  const reduxCart = useSelector((s) => s.cart.items);

  const cart = useMemo(() => (
    cartItems || reduxCart
  ), [cartItems, reduxCart]);

  /** Map of product name -> cart count */
  const currentCart = useMemo(() => (
    cart.reduce((acc, val) => {
      acc.set(val.name, (acc.get(val.name) || 0) + val.amount);
      return acc;
    }, new Map<string, number>())
  ), [cart]);

  /** Map of product name -> total count (including cart) */
  const productTotals = useMemo(() => {
    const values = new Map<string, number>();
    if (!data) return values;

    data.products.forEach((p) => {
      const cartCount = currentCart.get(p.name) || 0;
      values.set(p.name, p.limits.currentQuantity + cartCount);
    });

    return values;
  }, [data, currentCart]);

  /** Map of itemType -> {count, limit} */
  const limits = useMemo(() => {
    const values = new Map<string, { current: number, quantity: number }>();
    if (!data) return values;

    data.products.forEach((p) => {
      const old = values.get(p.itemType);
      const current = (currentCart.get(p.name) || 0) * p.limits.factor;
      if (!old) {
        const limit = data.limits.find((l) => l.itemType === p.itemType);
        const quantity = limit?.quantity || 0;
        const oldAmount = limit?.currentQuantity || 0;
        values.set(p.itemType, { current: oldAmount + current, quantity });
      } else {
        values.set(p.itemType, { ...old, current: old.current + current });
      }
    });

    return values;
  }, [data, currentCart]);

  /** Get remaining allowance for [prod] */
  const getAllowed = useCallback((prod: Product): number => {
    const vals1 = limits.get(prod.itemType);
    const lim1 = vals1?.quantity || 0;
    const lim2 = prod.limits.quantity;
    const total1 = vals1?.current || 0;
    const total2 = productTotals.get(prod.name) || 0;
    const factor1 = prod.limits.factor;

    const lim1Remaining = (lim1 - total1) / factor1;
    const lim2Remaining = (lim2 - total2);

    return Math.min(lim1Remaining, lim2Remaining);
  }, [limits, productTotals]);

  return getAllowed;
};
