import { configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { loggerMiddleware } from './loggerMiddleware';
import webshop, { webshopApi } from './webshop';
import cart from './cart';
import version, { versionApi } from './version';

// Put additional reducers here
export const store = configureStore({
  reducer: {
    cart,
    webshop,
    version,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  })
    .concat(webshopApi.middleware)
    .concat(versionApi.middleware)
    .concat(loggerMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
