import React, {
  FC,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import {
  AppBar,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import HomeIcon from '@mui/icons-material/Home';
import BackIcon from '@mui/icons-material/ChevronLeft';
import SearchIcon from '@mui/icons-material/Search';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { usePagePath, usePageType } from '../../core/hooks/usePageType';
import { CartIcon } from '../cartIcon';
import { Profile } from './partials/profile';
import { useStateUrl } from '../../core/hooks/params';

export const Header: FC<{
  children?: ReactNode,
  disableHome?: boolean,
  showBack?: boolean,
  extraMenuItems?: ReactNode,
  search?: boolean,
  }> = ({
    children,
    disableHome = false,
    showBack = false,
    extraMenuItems = null,
    search = false,
  }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchFor, setSearchFor] = useState<string>('');
    const [
      urlSearchValue,
      setSearch,
    ] = useStateUrl<string|undefined>('search', undefined, true, true);

    useEffect(() => {
      setSearchFor(urlSearchValue ?? '');
    }, [urlSearchValue]);

    const type = usePageType();

    const pagePath = usePagePath();

    const cartPath = pagePath && `${pagePath}/kasse`;

    const theme = useTheme();
    /** Is small (narrow page) */
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));

    /** Search for current search string */
    const doSearch = () => {
      if (search !== undefined && searchFor !== '') {
        setSearch(searchFor);
      }
    };

    /** Clear the search */
    const clearSearch = () => {
      setSearchFor('');
      setSearch(undefined);
    };

    const handleSearchKeyDown = (evt: React.KeyboardEvent<HTMLDivElement>) => {
      if (evt.code === 'Enter' || evt.code === 'NumpadEnter') doSearch();
    };

    return (
      <AppBar component="nav" position="sticky">
        <Toolbar>
          <Box sx={{ flex: 1, display: 'flex', gap: 5 }}>
            <Box display="flex" alignItems="center" gap={1}>
              {!disableHome && (
                <IconButton
                  component={Link}
                  to="/"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                >
                  <HomeIcon />
                </IconButton>
              )}
              {showBack && (
                <IconButton
                  color="secondary"
                  onClick={() => navigate(-1)}
                >
                  <BackIcon sx={{ fontSize: 32 }} />
                </IconButton>
              )}
              <Typography sx={{ fontSize: 20 }}>
                {children}
              </Typography>
            </Box>
            {!isSm && search && (
              <TextField
                sx={{
                  borderRadius: 1,
                  backgroundColor: '#ffffff',
                  opacity: 1,
                }}
                value={searchFor}
                onChange={(e) => setSearchFor(e.target.value)}
                size="small"
                placeholder="Søk..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton onClick={doSearch}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={clearSearch}
                        disabled={searchFor === ''}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onKeyDown={handleSearchKeyDown}
              />
            )}
          </Box>
          {cartPath && type && (
            <CartIcon
              type={type}
              onClick={() => (
                location.pathname === cartPath
                  ? navigate(-1)
                  : navigate(cartPath)
              )}
            />
          )}
          <Profile extraMenuItems={extraMenuItems} />
        </Toolbar>
        {search && isSm && (
        <Toolbar>
          <TextField
            sx={{
              borderRadius: 1,
              backgroundColor: '#ffffff',
              opacity: 1,
            }}
            fullWidth
            value={searchFor}
            onChange={(e) => setSearchFor(e.target.value)}
            size="small"
            placeholder="Søk..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton onClick={doSearch}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={clearSearch}
                    disabled={searchFor === ''}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onKeyDown={handleSearchKeyDown}
          />
        </Toolbar>
        )}
      </AppBar>
    );
  };
