/* eslint-disable react/jsx-props-no-spreading */
import { TextField, TextFieldProps } from '@mui/material';

export const InputField = ({
  onChange = () => null,
  ...props
}: Omit<TextFieldProps, 'onChange'> & {
    onChange?: (val: string) => void,
}) => (
  <TextField
    onChange={(e) => onChange(e.target.value)}
    {...props}
  />
);
