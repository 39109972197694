import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { pluralize } from '../../core/helpers/functions';
import { CartItem } from '../../core/redux/cart';
import { ProductOrderCard } from '../../pages/checkoutPage/partials/card';
import { DataField } from '../dataField';
import { InputField } from '../inputField';
import { Order } from '../../core/types/order';
import { useGetAllowed } from '../../core/helpers/limits';
import { UpdateOrder } from '../../core/types/api/updateOrder';
import { useGetClothingQuery, usePutOrderMutation } from '../../core/redux/webshop';
import { OrderStatusIcon } from '../OrderStatusIcon';
import { RegionRadio } from '../regionRadio';
import { CommentModal } from './commentModal';
import { Product } from '../../core/types/product';

export const OrderDetails = ({
  order,
  editable = false,
}: {
  order: Order,
  editable?: boolean,
}) => {
  const { data, isFetching: isFetchingClothing } = useGetClothingQuery(order?.employeeId);

  const [urgent, setUrgent] = useState<boolean>(order.urgent);
  const [region, setRegion] = useState<number>(1);
  const [items, setItems] = useState<CartItem[]>([]);
  const [comment, setComment] = useState<string>('');
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [commentModal, setCommentModal] = useState<'reject'|'approve'|undefined>();

  const navigate = useNavigate();
  const [update] = usePutOrderMutation();

  const getAllowed = useGetAllowed(order?.employeeId, items);

  const isExtra = useMemo(() => (items.some((i) => getAllowed(i.product) < 1)), [items, getAllowed]);

  const updateOrder = (approved?: boolean) => {
    if (!order) return;
    const body: UpdateOrder = {
      urgent,
      projectId: order.projectId,
      regionId: region,
      approved,
      comment,
      rows: items.map((i) => ({ id: i.id, amount: i.amount, comment: i.comment })),
    };
    update({ orderId: order.uid, body }).unwrap().then(() => {
      toast.success('Bestilling oppdatert');
      navigate(-1);
    });
  };

  useEffect(() => {
    if (!order) return;
    setItems(order?.rows.map((r) => {
      const product = data?.products.find((p) => p.name === r.product) || {
        name: r.product || r.description,
        productGroupCode: '',
        productGroup: '',
        itemType: '',
        amountPerUnit: 1,
        items: [],
        limits: {
          currentQuantity: 0,
          factor: 0,
          months: 0,
          quantity: 0,
        },
      };
      return ({
        id: r.itemId,
        type: order.type,
        name: r.description,
        image: r.image,
        size: r.size,
        amount: r.amount,
        comment: r.comment,
        productGroupCode: r.productGroupCode,
        product: {
          ...product,
          limits: {
            ...product.limits,
            // Remove the amount in this order to show the original amount the
            // user saw when making the order
            currentQuantity: Math.max(0, product.limits.currentQuantity - r.amount),
          },
        },
      });
    }) || []);
    setRegion(order.regionId);
    setComment(order.comment || '');
    setUrgent(order.urgent || false);
    setIsChanged(false);
  }, [order, data]);

  const deleteCartItem = (id: string) => {
    setItems(items.filter((s) => s.id !== id));
    setIsChanged(true);
  };
  const addQuantity = (id: string) => {
    setItems(items.map((i) => (i.id === id ? { ...i, amount: i.amount + 1 } : i)));
    setIsChanged(true);
  };
  const subQuantity = (id: string) => {
    const item = items.find((s) => s.id === id);
    if (!item) return;
    if ((item.amount - 1) < 1) return;
    // eslint-disable-next-line no-return-assign
    setItems(items.map((i) => (i.id === id ? { ...i, amount: i.amount - 1 } : i)));
    setIsChanged(true);
  };
  const updateComment = (id: string, c: string) => {
    setItems(items.map((i) => (i.id === id ? { ...i, comment: c } : i)));
    setIsChanged(true);
  };

  const hasComment = useMemo((): boolean => comment.trim().length !== 0, [comment]);

  const commentModalSubmit = (t: 'reject'|'approve'): void => {
    if (t === 'reject') updateOrder(false);
    else updateOrder(true);
  };

  const maxWidth = editable ? 1600 : 1200;

  const subtitleFor = (product: Product) => {
    if (order.type !== 'Clothing') return undefined;

    if (isFetchingClothing) return null;

    const timeframe = product.limits.months % 12 === 0
      ? `${product.limits.months / 12} år`
      : pluralize(product.limits.months, 'måned');

    return (`${product.limits.currentQuantity} / ${product.limits.quantity} tatt ut på ${timeframe}`);
  };

  return (
    <Box
      sx={{
        gap: 2,
        width: '100%',
        maxWidth,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        '@media (max-width:800px)': {
          alignItems: 'stretch',
          justifyContent: 'unset',
          flexDirection: 'column',
        },
      }}
    >
      {editable && (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          flexWrap: 'wrap',
          fontSize: 18,
          flex: 1,
          '@media (max-width:800px)': {
            flex: 'unset',
            flexDirection: 'row',
            justifyContent: 'space-between',
          },
        }}
      >
        <DataField label="Bestiller">
          {`${order?.employee?.fullName ?? order?.createdByName} - ${order?.employeeId}`}
        </DataField>
        <DataField label="Tlf">
          {order?.employee?.mobilePhoneWork}
        </DataField>
        <DataField label="Epost">
          {order?.employee?.email}
        </DataField>
        <DataField label="Lønnskategori">
          {order?.employee?.jobRole}
        </DataField>
        <DataField label="Leder">
          {`${order?.employee?.leader} - ${order?.employee?.leaderId}`}
        </DataField>
        <DataField label="Bestilt">
          {format(new Date(order?.createdTimestamp), "dd.MM.yyyy 'kl.' HH:mm")}
        </DataField>
      </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        flex={3}
        gap={2}
      >
        <Box className="checkout-items-container">
          {items.sort((a, b) => a.name.localeCompare((b.name, 'nb'))).map((i) => {
            const { product } = i;
            return (
              <ProductOrderCard
                key={i.id}
                item={i}
                getAllowed={getAllowed}
                subQuantity={() => subQuantity(i.id)}
                addQuantity={() => addQuantity(i.id)}
                deleteCartItem={() => deleteCartItem(i.id)}
                setComment={(id, c) => updateComment(id, c)}
                showComment={order.type === 'Clothing'}
                disableEdit={!editable}
                subtitle={subtitleFor(product)}
              />
            );
          })}
        </Box>
        {order.type === 'Equipment' && (
        <Box sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
        >
          <Box display="flex" flexDirection="column">
            <FormLabel id="delivery-method-label">Belastes</FormLabel>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
              }}
              flexGrow={1}
              flexDirection="row"
              gap={2}
              width="100%"
            >
              {order.machine ? (
                <>{order.machine.internalNumber} - {order.machine.subCategoryName} - {order.machine.modelName}</>
              ) : (
                <>{order.project?.id} - {order.project?.projectName}</>
              )}
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            <FormLabel id="delivery-method-label">Levering</FormLabel>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
              }}
              flexGrow={1}
              flexDirection="row"
              gap={2}
              width="100%"
            >
              {order.deliveryMethod === 'Pickup' ? (
                <>Hentes</>
              ) : (
                <>Ønskes levert</>
              )}
            </Box>
            {order.deliveryMethod === 'DeliveryRequested' && (
            <InputField
              value={order.deliveryComment}
              label="Leveringskommentar"
              disabled
              sx={{ mt: 2 }}
            />
            )}
          </Box>
        </Box>
        )}
        <Box sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
        >
          { order.type === 'Clothing' && (
          // Approval comment only visible for clothing
          <InputField
            // Hide comment content while modal is active
            value={commentModal ? '' : comment}
            onChange={setComment}
            label="Kommentar"
            size="small"
            multiline
            minRows={2}
            maxRows={5}
            required={isChanged}
            disabled={!editable}
          />
          )}
          <Box sx={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
          >
            <Box display="flex" flex={1}>
              <RegionRadio region={region} setRegion={setRegion} row disabled={!editable} />
            </Box>
            {order.type === 'Clothing' && (
            <Box display="flex" gap={1} mt={2}>
              <FormControlLabel
                label="Hastebestilling"
                disabled={items.length < 1 || !editable}
                control={<Checkbox sx={{ py: 0.5 }} checked={urgent} onChange={() => setUrgent(!urgent)} />}
              />
              <FormControlLabel
                label="Ekstrabestilling"
                disabled
                control={<Checkbox sx={{ py: 0.5 }} checked={isExtra} />}
              />
            </Box>
            )}
            <Box display="flex" py={0.5} mt={2} flex={1} justifyContent="flex-end" gap={1}>
              {editable && (
              <>
                <Box display="flex">
                  <Button
                    disabled={items.length < 1}
                    variant="contained"
                    color="error"
                    onClick={() => {
                      if (!hasComment) setCommentModal('reject');
                      else updateOrder(false);
                    }}
                  >Avslå
                  </Button>
                </Box>
                <Button
                  disabled={items.length < 1}
                  variant="contained"
                  onClick={() => {
                    if (isChanged && !hasComment) setCommentModal('approve');
                    else updateOrder(true);
                  }}
                >
                  Godkjenn
                </Button>
              </>
              )}
              {!editable && (
              <OrderStatusIcon currStatus={order.status} />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {editable && (
        <CommentModal
          open={!!commentModal}
          value={comment}
          onChange={setComment}
          onSubmit={(t) => commentModalSubmit(t)}
          onClose={() => {
            setCommentModal(undefined);
            // Comment would have been empty when we started, so reset it
            setComment('');
          }}
          type={commentModal}
        />
      )}
    </Box>
  );
};
