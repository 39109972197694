import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useGetRegionsQuery } from '../../core/redux/webshop';

export const RegionRadio = ({
  row = false,
  disabled = false,
  region,
  setRegion = () => null,
}: {
  row?: boolean,
  disabled?: boolean,
  region: number,
  setRegion: (id: number) => void,
}) => {
  const { data: regions } = useGetRegionsQuery();

  return (
    <FormControl>
      <FormLabel id="region-radio-label">Leveringssted</FormLabel>
      <RadioGroup
        aria-labelledby="region-radio-label"
        name="region-radio"
        row={row}
        value={region}
        onChange={(v) => setRegion(parseInt(v.currentTarget.value, 10))}
      >
        {regions && regions.map((r) => (
          <FormControlLabel key={r.id} value={r.id} disabled={disabled} control={<Radio />} label={r.name} />

        ))}
      </RadioGroup>
    </FormControl>
  );
};
