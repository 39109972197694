import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import CategoryIcon from '@mui/icons-material/Category';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  addCartItem,
  addQuantity,
  deleteCartItem,
  subQuantity,
} from '../../core/redux/cart';
import { Product } from '../../core/types/product';
import { useDispatch, useSelector } from '../../core/hooks/redux';
import { ConfirmModal } from './confirm';
import { OrderType } from '../../core/types/enums/orderType';
import { sortObjBySize } from '../../core/helpers/sizeOrder';
import { ItemControl } from '../itemControl';
import ImageMissing from '../../assets/image_missing.png';
import { config } from '../../config';

/** Card for individual products */
export const ProductCard = ({
  product,
  type,
  remainingAllowance = 0,
} : {
  product: Product,
  type: OrderType,
  remainingAllowance?: number,
}) => {
  const dispatch = useDispatch();
  /** When true, the next item added to cart will trigger a comment box */
  const disabled = type === 'Clothing' && remainingAllowance < 1;
  const isSingleSize = useMemo(() => (product.items?.length <= 1), [product]);
  const [useFallbackImage, setUseFallbackImage] = useState<boolean>(false);
  const [confirmProduct, setConfirmProduct] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [item, setItem] = useState<{id: string, size: string|undefined}|undefined>(
    isSingleSize
      ? { id: product.items[0].id, size: product.items[0]?.size }
      : undefined,
  );

  const cart = useSelector((s) => (s.cart.items));

  const cartCount = useMemo(() => cart.reduce((acc, val) => (
    acc + (val.id === item?.id
      ? val.amount
      : 0
    )), 0), [cart, isOpen]);

  const confirmReason = useMemo(() => {
    const itemLimit = product.limits?.quantity;
    const itemCount = product.limits?.currentQuantity || 0 + cartCount;
    return (itemCount >= itemLimit ? 'product' : 'category');
  }, [product, cartCount]);

  const cartComment = useSelector((s) => (
    item && s.cart.items.find((i) => i.name === product.name && i.id === item.id)?.comment
  ));

  useEffect(() => {
    const handler = () => {
      setIsOpen(false);
    };
    window.addEventListener('scroll', handler);
    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, []);

  const addItem = useCallback((comment?: string) => {
    if (!item) return;
    dispatch(
      addCartItem(
        {
          id: item.id,
          type,
          name: product.name,
          image: product.image,
          size: item.size,
          productGroupCode: product.productGroupCode,
          amount: 1,
          cost: 1,
          product,
          comment,
        },
      ),
    );
  }, [item, dispatch, addCartItem, product]);

  const confirmSubmit = useCallback((comment: string) => {
    setConfirmProduct(false);
    addItem(comment);
  }, [setConfirmProduct, addItem]);

  const image = useMemo(() => {
    const url = product.image ? `${config.API_URL}/${product.image}` : product.image;
    return (
      url && !useFallbackImage
        ? url
        : ImageMissing
    );
  }, [product, useFallbackImage]);

  return (
    <Card
      key={product.name}
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        minWidth: 300,
        minHeight: 300,
        maxWidth: 300,
        margin: 1,
        overflow: 'visible',
      }}
    >
      <Typography
        sx={{
          display: 'flex',
          alignSelf: 'flex-start',
          fontSize: 12,
          fontFamily: 'Segoe UI, droid sans, sans-serif',
          backgroundColor: '#004b8b',
          color: 'white',
          padding: '2px 6px',
          borderRadius: '4px 0 4px 0',
          fontWeight: 'bold',
        }}
      >
        {product.itemType}
      </Typography>
      {image && (
        <CardMedia
          component="img"
          alt="Produktbilde"
          height={260}
          image={image}
          sx={{ objectFit: 'contain', marginTop: 1 }}
          onError={() => setUseFallbackImage(true)}
        />
      )}
      {!image && (
      <CategoryIcon sx={{ height: 260, width: 100, margin: 1 }} />
      )}
      <CardContent sx={{ flex: 1, height: 110 }}>
        <Typography display="flex" variant="h6" component="div">
          {product.name}
        </Typography>

        {type === 'Clothing' && (
        <Typography display="flex" variant="body2" sx={{ color: '#666' }} component="div">
          {disabled && cartCount <= 0 && 'krever godkjenning' }
          {!disabled && `${remainingAllowance} tilgjengelig`}
        </Typography>
        )}
      </CardContent>
      <CardActions sx={{ m: 1, justifyContent: 'space-between', height: 30 }}>
        {isSingleSize ? (
          <div />
        ) : (
          <FormControl sx={{ minWidth: 120 }} size="small">
            <InputLabel id="size-select-label">Størrelse</InputLabel>
            <Select
              labelId="size-select-label"
              open={isOpen}
              onOpen={() => {
                setIsOpen(true);
              }}
              onClose={() => {
                setIsOpen(false);
              }}
              value={item?.id || ''}
              label="Størrelse"
              onChange={(s) => {
                const i = product.items?.find((x) => x.id === s.target.value);
                if (!i) return;
                setItem({ id: i.id, size: i?.size });
              }}
            >
              {[...product.items ?? []].sort(sortObjBySize).map((i) => (
                <MenuItem key={`${product.name}-${i.size}`} value={i.id}>
                  {i.size}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {item && (
          <ItemControl
            subDisabled={cartCount < 1}
            amount={cartCount * product.amountPerUnit}
            size="large"
            subQuantity={() => {
              if (cartCount === 1) {
                dispatch(deleteCartItem(item.id));
                return;
              }
              dispatch(subQuantity(item.id));
            }}
            addQuantity={() => {
              if (disabled && !cartComment) {
                setConfirmProduct(true);
                return;
              }
              if (cartCount < 1) {
                addItem();
                return;
              }
              dispatch(addQuantity(item.id));
            }}
          />
        )}
      </CardActions>
      <ConfirmModal
        open={confirmProduct}
        onSubmit={confirmSubmit}
        onClose={() => setConfirmProduct(false)}
        reason={confirmReason}
        productName={product.name}
        categoryName={product.productGroup}
      />
    </Card>
  );
};
