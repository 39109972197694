/** Get a value for a given size that's comparable to other sizes */
export const getSizeValue = (size: string|number) => {
  if (typeof size === 'number') return size;
  const s = size.toUpperCase().trim();
  if (s === 'M') return 0;
  let value = parseInt(s.replace(/[^0-9-]/g, ''), 10) || 0;
  // All values other than M is at least 1
  if (s.endsWith('S') || s.endsWith('L')) {
    value += 1;
    value += s.match(/X/g)?.length ?? 0;
  }
  if (s.includes('S')) return -1 * value;
  return value;
};

/** Sort list of size strings or numbers */
export const sortBySize = (a: string|number, b: string|number) => (
  getSizeValue(a) - getSizeValue(b)
);

/** Sort objects by a size property */
export const sortObjBySize = (a: {size: number|string}, b: {size: number|string}) => (
  sortBySize(a.size, b.size)
);
