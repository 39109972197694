// eslint-disable-next-line import/no-duplicates
import { format as baseFormat, formatISO, startOfDay } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { nb } from 'date-fns/locale';
import { DateTime } from '../types/util/dateTime';

export const format = (d: Date|DateTime, f: string) => (
  baseFormat(typeof d === 'string' ? new Date(d) : d, f, { locale: nb })
);

export const formatISODate = (d: Date) => (
  formatISO(startOfDay(d), { representation: 'date' })
);
