import { OrderItem } from '../../../components/orderItem';
import { Order } from '../../../core/types/order';
import { useConvertProcess } from './useConvertProcess';

export const ProcessOrderItem = ({
  order,
}: {
  order: Order,
}) => {
  const data = useConvertProcess(order);
  return (
    <OrderItem order={data} />
  );
};
