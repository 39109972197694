import { Box, Typography } from '@mui/material';

export const NotFound = () => (
  <Box display="flex" flex={1} alignItems="center" height="99vh" justifyContent="center">
    <Box display="flex" alignItems="center" flexDirection="column">
      <Typography fontSize="10vmax">404</Typography>
      <Typography textAlign="center" fontSize="1.5vmax">Siden finnes ikke<br /><a href="/">Gå til hjemmesiden</a></Typography>
    </Box>
  </Box>
);
