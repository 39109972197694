import { ReactNode, useMemo } from 'react';
import {
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';
import { compareDesc } from 'date-fns';
import { DateTime } from '../../core/types/util/dateTime';
import { format } from '../../core/helpers/dates';
import { useGetApprovalOrdersQuery } from '../../core/redux/webshop';
import { Header } from '../../components/header';
import { ProcessOrderItem } from './partials/processOrderItem';
import './style.scss';

export const ProcessOrdersPage = () => {
  const {
    data: rawOrders = [],
    isLoading,
  } = useGetApprovalOrdersQuery();

  const orders = useMemo(() => {
    const days = new Map<string, ReactNode[]>();
    const add = (date: Date|DateTime, data: ReactNode) => {
      const day = format(date, 'EEEE do MMM yyyy');
      if (days.has(day)) {
        days.set(day, [...days.get(day) as ReactNode[], data]);
      } else {
        days.set(day, [data]);
      }
    };
    [...rawOrders]
      .sort((a, b) => compareDesc(new Date(a.createdTimestamp), new Date(b.createdTimestamp)))
      .forEach((o) => {
        add(o.createdTimestamp, <ProcessOrderItem key={o.uid} order={o} />);
      });
    const items: ReactNode[] = [];
    days.forEach((v, k) => {
      items.push(
        // eslint-disable-next-line react/no-array-index-key
        <Box key={k} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <div className="day-header mt20 mb20">{k}</div>
          {v}
        </Box>,
      );
    });
    if (items.length <= 0) {
      return (
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          paddingTop: '4vh',
        }}
        >
          {isLoading && (
            <CircularProgress />
          )}
          {!isLoading && (
            <Typography sx={{ color: '#666', fontSize: 20 }}>
              Ingen bestillinger i denne perioden
            </Typography>
          )}
        </Box>
      );
    }
    return items;
  }, [rawOrders, isLoading]);

  return (
    <Box sx={{ flex: 1 }} className="orders-page">
      <Header>
        Behandle bestillinger
      </Header>
      <Box
        sx={{
          flex: 1,
          paddingX: 1,
          paddingY: '3vh',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '800px',
          gap: 2,
        }}
        >
          {orders}
        </Box>
      </Box>
    </Box>
  );
};
