import { Box, Typography } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';
import { Order } from '../../../core/types/order';
import { OrderItemType } from '../../../components/orderItem';

export const useConvertEquipment = (order: Order): OrderItemType => {
  const obj: OrderItemType = {
    id: order.uid,
    path: `/bestillinger/${order.uid}`,
    status: order.status,
    title: `${order.createdByName} - ${order.employeeId}`,
    type: 'Equipment',
    items: (
      <Box>
        {order.rows.slice(0, 3).map((r) => <Typography key={r.uid}>{`${r.amount} x ${r.description}`}</Typography>)}
        {order.rows.length > 3 && <Typography fontWeight="bold">...</Typography>}
      </Box>
    ),
    totalAmount: (
      order.rows.reduce((acc, curr) => acc + curr.amount, 0)
    ),
    icons: (
      <ConstructionIcon color="primary" />
    ),
  };

  return obj;
};
