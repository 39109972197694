import { ReactNode, useMemo } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Typography,
} from '@mui/material';
import { subYears } from 'date-fns';
import { useStateUrl } from '../../core/hooks/params';
import { DateTime } from '../../core/types/util/dateTime';
import { format, formatISODate } from '../../core/helpers/dates';
import { useGetUserOrdersQuery } from '../../core/redux/webshop';
import { ClothingOrderItem } from './partials/clothingOrderItem';
import { EquipmentOrderItem } from './partials/equipmentOrderItem';
import './style.scss';
import { Header } from '../../components/header';
import { Checkbox } from '../../components/checkbox';

export const OrdersPage = () => {
  const [selection, setSelection] = useStateUrl<'History'|'InProgress'>('status', 'History', true);
  const [showClothesText, setShowClothes] = useStateUrl<'true'|'false'>('klaer', 'true', true);
  const [showEquipmentText, setShowEquipment] = useStateUrl<'true'|'false'>('utstyr', 'true', true);
  const showClothes = showClothesText === 'true';
  const showEquipment = showEquipmentText === 'true';

  const {
    data: rawOrders = [],
    isLoading,
  } = useGetUserOrdersQuery(formatISODate(subYears(new Date(), 1)));

  const filteredOrders = useMemo(
    () => rawOrders.filter((o) => (selection === 'InProgress' ? o.status === 'RequiresApproval' : o.status !== 'RequiresApproval')),
    [selection, rawOrders],
  );
  const clothes = filteredOrders.filter((o) => o.type === 'Clothing');
  const equipment = filteredOrders.filter((o) => o.type === 'Equipment');

  const orders = useMemo(() => {
    const days = new Map<string, ReactNode[]>();
    const add = (date: Date|DateTime, data: ReactNode) => {
      const day = format(date, 'EEEE do MMM yyyy');
      if (days.has(day)) {
        days.set(day, [...days.get(day) as ReactNode[], data]);
      } else {
        days.set(day, [data]);
      }
    };
    if (showClothes) {
      clothes.forEach((o) => {
        add(o.createdTimestamp, <ClothingOrderItem order={o} key={o.uid} />);
      });
    }
    if (showEquipment) {
      equipment.forEach((o) => {
        add(o.createdTimestamp, <EquipmentOrderItem order={o} key={o.uid} />);
      });
    }
    const items: ReactNode[] = [];
    days.forEach((v, k) => {
      items.push(
        // eslint-disable-next-line react/no-array-index-key
        <Box key={k} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <div className="day-header mt20 mb20">{k}</div>
          {v}
        </Box>,
      );
    });
    if (items.length <= 0) {
      return (
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          paddingTop: '4vh',
        }}
        >
          {isLoading && (
            <CircularProgress />
          )}
          {!isLoading && (
            <Typography sx={{ color: '#666', fontSize: 20 }}>
              Ingen bestillinger i denne perioden
            </Typography>
          )}
        </Box>
      );
    }
    return items;
  }, [showClothes, showEquipment, filteredOrders, isLoading]);

  return (
    <Box sx={{ flex: 1 }} className="orders-page">
      <Header>
        Mine Bestillinger
      </Header>
      <Box
        sx={{
          flex: 1,
          paddingX: 1,
          paddingY: '3vh',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '800px',
          gap: 2,
        }}
        >
          <div className="header">
            <span>
              <ButtonGroup fullWidth variant="contained" aria-label="outlined primary button group">
                <Button variant={selection === 'History' ? 'contained' : 'outlined'} onClick={() => setSelection('History')}>Historikk</Button>
                <Button variant={selection === 'InProgress' ? 'contained' : 'outlined'} onClick={() => setSelection('InProgress')}>Pågående</Button>
              </ButtonGroup>
            </span>
            <Box sx={{
              display: 'flex',
              gap: 2,
              pt: 2,
              color: 'black',
            }}
            >
              <Checkbox disabled={!showEquipment} checked={showClothes} onChange={(v) => showEquipment && setShowClothes(v ? 'true' : 'false')} label="Vis klær" />
              <Checkbox disabled={!showClothes} checked={showEquipment} onChange={(v) => showClothes && setShowEquipment(v ? 'true' : 'false')} label="Vis utstyr" />
            </Box>
          </div>
          {orders}
        </Box>
      </Box>
    </Box>
  );
};
