import { Box, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

export const ItemControl = ({
  subDisabled = false,
  addDisabled = false,
  amount,
  subQuantity,
  addQuantity,
  size = 'medium',
}: {
  subDisabled?: boolean,
  addDisabled?: boolean,
  amount: number,
  subQuantity: () => void,
  addQuantity: () => void,
  size?: 'small'|'medium'|'large'
}) => {
  const sizeMap: { [key: string]: number } = {
    small: 14,
    medium: 16,
    large: 18,
  };
  const fontSize = sizeMap[size];
  return (
    <Box display="flex" alignItems="center" gap={size === 'large' ? 1.5 : undefined}>
      <IconButton
        disabled={subDisabled}
        onClick={subQuantity}
      >
        <RemoveIcon sx={{ fontSize, color: !subDisabled ? '#454545' : undefined }} />
      </IconButton>
      <Typography sx={{ fontSize: fontSize + 2 }}>{amount}</Typography>
      <IconButton
        onClick={addQuantity}
        disabled={addDisabled}
      >
        <AddIcon sx={{ fontSize, color: !addDisabled ? '#454545' : undefined }} />
      </IconButton>
    </Box>
  );
};
